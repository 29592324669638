import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/system/Box';
import FileUpload from '@rs-ui/views/ImportView/FileUpload';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import { useImportDispatcher } from '@worklist-2/ui/src/views/ImportView/useImportDispatcher';
import sleep from '@rs-core/utils/sleep';
import { useImportTabContext } from '@rs-core/context/ImportTabContext';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useAuth } from '@rs-core/context/UserAuthContext';
import axios from 'axios';
import LaptopIcon from '@mui/icons-material/Laptop';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useSettingsViewContext } from '@worklist-2/patientPortal/src/context/SettingsViewContext';
import { useTranslation } from 'react-i18next';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import useHandleFiles from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/hooks/useHandleFiles';
import _ from 'lodash';
import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';
import { ALLOWED_DOCUMENT_MIME_TYPES, ALLOWED_DOCUMENT_VIDEO_MIME_TYPES } from '../../../../../consts';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SectionUploadDocument = ({
	newDesign = false,
	filesToUpload = [],
	iconSx,
	isNewSetting,
	isAppointment,
	reloadAppointmentStudy,
	patient,
	takeConsentBeforeUpload,
	consent = false,
}) => {
	const { user, aggregateDetails } = useSettingsViewContext();
	const { accessToken, sessionId } = useAuth();
	const __config = useConfig();
	const appURL = __config.data_sources.blume;
	const [userUpload, setUserUpload] = useState(false);
	const [importing, setImporting] = useState(false);
	const currentUploadingFiles = useRef([]);
	const { t } = useTranslation('appointments');
	const { setType = () => {}, setToastMsg } = useToastMessageContext();
	const fileInputRef = useRef(null);
	const openFileDialog = () => fileInputRef?.current?.click();
	const [uploadedFilesQuantity, setUploadedFilesQuantity] = useState(0);
	const { onUploadFile } = useHandleFiles();
	const { setPriorStudies } = useNewAppointmentContentV2Context();
	const phoenixBlumeTakeConsentWhileDocDicomUpload = useBooleanFlagValue(
		'phoenix-blume-take-consent-while-doc-dicom-upload'
	);

	const phoenixBlumeTurnOffVideoFileUpload = useBooleanFlagValue('phoenix-blume-turn-off-video-file-upload');

	let importDispatcher = null;
	const studyDataLoader = null;
	const patientDataLoader = null;
	const coordinatorWorkerURL = `${__config.blume_base_url}scripts/importcoordinatorworker.js`;

	const {
		filesUploading,
		setFilesUploading,
		setShowFileUploadWindow,
		setReloadDocumentSection,
		setRecentUploadedQuantity,
	} = useImportTabContext();

	if (!window.importDispatcher) {
		importDispatcher = useImportDispatcher(
			studyDataLoader,
			patientDataLoader,
			accessToken,
			sessionId,
			coordinatorWorkerURL
		);

		window.importDispatcher = importDispatcher;
	} else {
		importDispatcher = window.importDispatcher;
	}

	const monitorImport = async () => {
		let updatedFileProgress = [];
		let uploadProgress = [];
		let currentImportState = true;
		let uploadResponse = '';
		const uploadedStudies = [];
		setImporting(true);
		while (currentImportState) {
			// wait for upload
			await sleep(500);
			// update individual file progree
			uploadProgress = importDispatcher.getFileUploadProgress();
			updatedFileProgress = [...currentUploadingFiles.current];
			uploadProgress?.forEach(fileProgress => {
				const fileIndex = updatedFileProgress.findIndex(_element => _element?.name === fileProgress.fileName);
				if (fileIndex > -1) {
					updatedFileProgress[fileIndex].progress = fileProgress.progress;
					updatedFileProgress[fileIndex].status = fileProgress.status;
				}
			});
			setFilesUploading(updatedFileProgress);
			currentImportState = importDispatcher.getImportStatus();
			uploadResponse = importDispatcher.getFileUploadStatus();
			if (!currentImportState) {
				setImporting(currentImportState);
				currentUploadingFiles.current = [];
				setRecentUploadedQuantity(uploadedFilesQuantity);
				if (!isAppointment) {
					setReloadDocumentSection(true);
				}
				setShowFileUploadWindow(false);
				setFilesUploading([]);
				if (reloadAppointmentStudy) {
					reloadAppointmentStudy();
					if (Array.isArray(uploadResponse) && uploadResponse.length) {
						uploadResponse.forEach(data => {
							if (data.messageEvent && data.messageEvent?.responseText) {
								uploadedStudies.push(data.messageEvent?.responseText);
							}
						});
					}
				}
			}
		}
		if (uploadedStudies.length) {
			setPriorStudies(uploadedStudies);
		}
		setUserUpload(false);
	};

	const handleInputFiles = async files => {
		setShowFileUploadWindow(true);
		setType('upload');
		const res = await axios.get(`${__config.data_sources.blume}Document`);
		const newFiles = [];
		const newDicomFile = [];

		if (isAppointment) {
			const nonDicomFiles = files.filter(file => {
				file.name.endsWith('doc') ||
					file.name.endsWith('docx') ||
					file.type === 'application/pdf' ||
					file.type.includes('image');
			});

			onUploadFile({ target: { files: nonDicomFiles } });
		}

		const currentUserDocumentIds = aggregateDetails?.myDocuments;
		const currentUserDocuments = _.intersectionWith(res.data, currentUserDocumentIds, (a, b) => a.id === b);

		for (const file of files) {
			file.progress = 0;
			if (
				res.data.some(existingFile => {
					const existingFilenameArr = existingFile.fileName.split('.');
					const existingFileExt = existingFilenameArr.at(-1);

					const uploadedFileNameArr = file.name.split('.');
					const uploadedFileNameExt = uploadedFileNameArr.at(-1);
					return existingFile.fileName === file.name && existingFileExt === uploadedFileNameExt;
				})
			) {
				setToastMsg(t('File already present'));
			} else if (file.type && file.type !== 'application/dicom') {
				newFiles.push(file);
			} else newDicomFile.push(file);
		} // send request to dispatcher

		setUploadedFilesQuantity(newFiles.length + newDicomFile.length);
		console.log(newFiles.length, 'gggggg');
		if (newFiles.length) {
			let url = `${appURL}Document?hash=${patient ? patient?.profileId : user?.profileId}`;
			if (phoenixBlumeTakeConsentWhileDocDicomUpload) {
				url = `${appURL}Document?hash=${
					patient ? patient?.profileId : user?.profileId
				}&sharedWithFacility=${consent}`;
			}

			await importDispatcher.importNonDicomFiles(newFiles, url);
			currentUploadingFiles.current = [...newFiles, ...filesUploading];
			setUserUpload(true);
		}
		if (newDicomFile.length && isNewSetting) {
			let url = `${appURL}Study/dicomuploadv2?userHash=${patient ? patient?.profileId : user?.profileId}`;
			if (phoenixBlumeTakeConsentWhileDocDicomUpload) {
				url = `${appURL}Study/dicomuploadv2?userHash=${
					patient ? patient?.profileId : user?.profileId
				}&sharedWithFacility=${consent}`;
			}
			await importDispatcher.importBlumeDicomFiles(newDicomFile, url);
			currentUploadingFiles.current = [...newDicomFile, ...filesUploading];
			setUserUpload(true);
		}
	};

	const handleChange = e => {
		e.preventDefault();
		const files = [];
		for (const acceptedFile of e.target.files) {
			const fileType = acceptedFile.type;

			if (
				![
					...ALLOWED_DOCUMENT_MIME_TYPES,
					...(phoenixBlumeTurnOffVideoFileUpload ? [] : ALLOWED_DOCUMENT_VIDEO_MIME_TYPES),
				].includes(fileType) &&
				fileType !== 'application/dicom' &&
				fileType
			) {
				setToastMsg(t('File type not supported'));
				return;
			}

			files.push(acceptedFile);
		}

		if (Boolean(takeConsentBeforeUpload) && typeof takeConsentBeforeUpload === 'function') {
			takeConsentBeforeUpload([true, files]);
		} else {
			handleInputFiles(files);
		}
	};

	const handleClick = () => {
		fileInputRef.current.value = null;
	};

	useEffect(() => {
		if (filesToUpload?.length > 0) {
			handleInputFiles(filesToUpload);
		}
	}, [filesToUpload]);

	useEffect(() => {
		if (userUpload && !importing) {
			monitorImport();
		}
	}, [userUpload, filesUploading]);

	return (
		<>
			{isAppointment ? (
				<Box
					sx={{
						maxWidth: '100%',
						backgroundColor: '#42A5F50D',
						color: '#42A5F5',
						fontFamily: 'Roboto',
						display: 'flex',
						alignItems: 'center',
						padding: '10px 20px 10px 25px',
						cursor: 'pointer',
					}}
					onClick={openFileDialog}
				>
					<LaptopIcon sx={{ marginRight: '10px' }} />
					{t('Upload from computer')}
					<input
						ref={fileInputRef}
						multiple
						data-testid="file-upload-input"
						name="files"
						style={{ display: 'none' }}
						type="file"
						onChange={handleChange}
					/>
				</Box>
			) : !newDesign ? (
				<Box className="importDetailBlume">
					<FileUpload isBlume handleInputFiles={handleInputFiles} />
				</Box>
			) : (
				<>
					<Tooltip title={t('Upload')}>
						<IconButton sx={{ height: '24px', padding: 0 }} onClick={openFileDialog}>
							<FileUploadOutlined
								sx={iconSx === false ? { display: 'none' } : { color: '#12121299', ...iconSx }}
							/>
						</IconButton>
					</Tooltip>
					<input
						ref={fileInputRef}
						multiple
						data-testid="file-upload-input"
						id="file-upload-input"
						name="files"
						style={{ display: 'none' }}
						type="file"
						onChange={handleChange}
						onClick={handleClick}
					/>
				</>
			)}
		</>
	);
};

export default SectionUploadDocument;
