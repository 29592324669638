import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import IconButton from '@mui/material/IconButton';
import useHelpCenter from '@rs-core/hooks/useHelpCenter';
import HelpContent from '@worklist-2/ui/src/views/HelpView/HelpCenter/HelpPopOver/HelpContent';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { ArticleWrapper, Flex } from '../HelpCenter/patientHelpStyles';
import PatientHelpPipChat from './PatientHelpPipChat';
import PatientHelpPipMenu from './PatientHelpPipMenu';
import { FooterContent, Header, MainContent, MiniHelpWrapper, StartChatButton } from './patientHelpPopoverStyles';
import { useTranslation } from 'react-i18next';

const PatientPortalHelpPopOver = () => {
	const { togglePopover, setCurrentSideTab, currentSideTab } = useHelpCenter();
	const [showingMenu, setShowingMenu] = useState(true);
	const [showingChat, setShowingChat] = useState(false);
	const [popOverContent] = useState('helpContent');
	const frameDim = { width: 565, height: window.innerHeight };
	const [dim, setDim] = useState([window.innerWidth, window.innerHeight]);
	const { t } = useTranslation('helpCenter');

	const bounds = useMemo(() => {
		const [innerWidth, innerHeight] = dim;
		return {
			left: -(innerWidth - frameDim.width) + 100,
			top: 0,
			right: innerWidth / 3 - frameDim.width,
			bottom: innerHeight - frameDim.height,
		};
	}, [dim]);

	useEffect(() => {
		window.addEventListener('resize', () => setDim([window.innerWidth, window.innerHeight]));
	}, []);

	const Content = useMemo(() => {
		let component = null;
		if (_.isObject(showingMenu)) {
			component = () => (
				<ArticleWrapper
					style={{
						height: '85vh',
						overflowY: 'auto',
						border: 0,
						margin: '5px 0px 10px 35px',
					}}
				>
					{showingMenu?.content}
				</ArticleWrapper>
			);
		} else if (_.isBoolean(showingMenu) && showingMenu === true) {
			return showingChat ? PatientHelpPipChat : PatientHelpPipMenu;
		} else {
			if (popOverContent === 'helpContent') {
				return HelpContent;
			}
		}
		return component;
	}, [showingMenu, showingChat]);

	const toggleChat = () => {
		setShowingMenu(true);
		setShowingChat(!showingChat);
	};

	return (
		<Draggable
			axis="both"
			handle=".handle"
			bounds={bounds}
			style={{ position: 'absolute' }}
			scale={1}
			defaultClassName="popover-menu"
		>
			<MiniHelpWrapper data-testid={'PatientPortalHelpPopOver'}>
				<Header className="handle" bordered={showingChat}>
					<Flex>
						{showingMenu === true ? (
							<div className="icon-btn">{t('HELP')}</div>
						) : (
							<IconButton
								onClick={() => {
									if (currentSideTab?.includes('?')) {
										const splitSideTabValue = currentSideTab?.split('?');
										setCurrentSideTab(splitSideTabValue[0]);
									} else {
										setCurrentSideTab(currentSideTab);
									}

									setShowingMenu(true);
								}}
								className="icon-btn"
							>
								<ChevronLeftIcon />
								{t('GO BACK TO MAIN MENU')}
							</IconButton>
						)}
					</Flex>
					<IconButton data-testid={'PatientPortalHelpPopOverClose'} onClick={() => togglePopover(false)}>
						<CloseIcon />
					</IconButton>
				</Header>
				<MainContent>
					{Content && (
						<Content
							setShowingMenu={setShowingMenu}
							style={{
								height: '95vh',
								overflowY: 'auto',
								border: 0,
								margin: '5px 0px 10px 15px',
							}}
						/>
					)}
				</MainContent>
				<FooterContent>
					<Flex direction={'column'} style={{ paddingLeft: 20 }}>
						<span>{showingChat ? 'Return to help section' : 'Still unclear on something?'}</span>
					</Flex>
					<StartChatButton onClick={toggleChat} outline={showingChat}>
						<SupportAgentIcon sx={{ mr: 1 }} />
						{showingChat ? 'CLOSE CHAT' : 'START CHAT'}
					</StartChatButton>
				</FooterContent>
			</MiniHelpWrapper>
		</Draggable>
	);
};

export default PatientPortalHelpPopOver;
