import { useEffect } from 'react';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import { useTranslation } from 'react-i18next';

const useNavigatorOnLine = () => {
	const { setToastMsg } = useToastMessageContext();
	const { t } = useTranslation('home');

	const setOffline = () => {
		setToastMsg(t('Offline state toast message'));
	};

	useEffect(() => {
		window.addEventListener('offline', setOffline);

		return () => {
			window.removeEventListener('offline', setOffline);
		};
	}, []);
};

export default useNavigatorOnLine;
