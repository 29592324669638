// React core
import React, { useEffect, useState } from 'react';

// MUI
import Box from '@mui/material/Box';

// Icons
import HomeOutlinedIcon from '@worklist-2/ui/src/assets/img/homeIcon.svg';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

// Components
import GenericDrawer from '@rs-ui/components/Drawers/GenericDrawer/GenericDrawer';
import EmergencyContact from '@worklist-2/patientPortal/src/views/NewSettingsView/Components/UserDetailDrawer/Details/EmergencyContact';
import InformationSection from '@worklist-2/patientPortal/src/views/NewSettingsView/Components/UserDetailDrawer/Details/InformationSection';

import { useTranslation } from 'react-i18next';

import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';

// Utils
import { useIsMobile } from '@rs-core/utils/responsiveUtils';

// API
import { useConfig } from '@rs-core/context/ConfigContext';
import getValueSetByName from '@worklist-2/patientPortal/src/api/getValueSetByName';

const UserDetailDrawer = ({
	isOpenDetail,
	setIsOpenDetail,
	isOaiNotification,
	fieldChangeRequest,
	updateNotification,
}) => {
	const { t } = useTranslation('profile');
	const [autoOpen, setAutoOpen] = useState(isOaiNotification ? 'Personal Information' : false);
	const [changeRequestField, setChangeRequestField] = useState(null);
	const [personalInfoFormFields, setPersonalInfoFormFields] = useState([]);

	const isMobile = useIsMobile();
	const __config = useConfig();

	useEffect(() => {
		const fetchPersonalInfoFormFields = async () => {
			const values = [
				{ label: 'First Name', name: 'firstName', type: 'text' },
				{ label: 'Middle Name', name: 'middleName', type: 'text' },
				{ label: 'Last Name', name: 'lastName', type: 'text' },
				{ label: 'Date of Birth', name: 'birthDate', type: 'date' },
				{
					label: 'Gender',
					name: 'gender',
					type: 'select',
					selectOptions: await getValueSetByName({ __config, name: 'sex' }),
				},

				{
					label: 'Races',
					name: 'race',
					type: 'multiSelect',
					selectOptions: await getValueSetByName({ __config, name: 'race' }),
				},

				{
					label: 'Ethnicity',
					name: 'ethnicity',
					type: 'multiSelect',
					selectOptions: await getValueSetByName({ __config, name: 'ethnicity' }),
				},

				{
					label: 'Birth Sex',
					name: 'birthSex',
					type: 'select',
					selectOptions: await getValueSetByName({ __config, name: 'sex' }),
				},

				{
					label: 'Language',
					name: 'language',
					type: 'multiSelect',
					selectOptions: await getValueSetByName({ __config, name: 'language' }),
				},

				{
					label: 'SSN',
					name: 'ssn',
					type: 'string',
				},

				{
					label: 'Marital Status',
					name: 'maritalStatus',
					type: 'select',
					selectOptions: await getValueSetByName({ __config, name: 'maritalStatus' }),
				},

				{
					label: 'Smoking Status',
					name: 'smokingStatus',
					type: 'select',
					selectOptions: [
						'Never Smoked',
						'Unknown if ever smoked',
						'Light Smoker',
						'Heavy Smoker',
						'Everyday Smoker',
						'Former Smoker',
					],
				},

				{
					label: 'Alcohol Use',
					name: 'alcoholUse',
					type: 'select',
					selectOptions: [t('Non-drinker'), t('Social'), t('Daily'), t('Heavy drinker')],
				},

				{ label: t('Mother’s Maiden Name'), name: 'motherMaidenName', type: 'text' },
			];

			return setPersonalInfoFormFields(values);
		};

		fetchPersonalInfoFormFields();
	}, []);

	const addressFormFields = [
		{ label: 'Country', name: 'country', type: 'text' },
		{ label: 'State/ Province', name: 'state', type: 'text' },
		{ label: 'Zip/Postal Code', name: 'zip', type: 'text' },
		{ label: 'City', name: 'city', type: 'text' },
		{ label: 'Address Line 1', name: 'address', type: 'text', fullWidth: true },
		// { label: t('Address Line 2'), name: 'addressLine2', type: 'text' },
	];

	const contactInfoFormFields = [
		{ label: 'Phone', name: 'phone', type: 'muiPhone', required: true },
		{ label: 'Email', name: 'email', type: 'text' },
	];

	useEffect(() => {
		if (fieldChangeRequest)
			setChangeRequestField(fieldChangeRequest === 'addressLine1' ? 'address' : fieldChangeRequest);
	}, [fieldChangeRequest]);

	useEffect(() => {
		if (changeRequestField)
			addressFormFields.forEach(element => {
				if (element.name === changeRequestField) setAutoOpen('Address');
			});
	}, [changeRequestField]);

	const UserDetailSections = () => (
		<Box
			sx={{
				pl: '27px',
				pr: '20px',
				pt: '15px',
			}}
		>
			<InformationSection
				isSectionEditable
				autoOpen={autoOpen === 'Personal Information'}
				fieldChangeRequest={changeRequestField}
				formFields={personalInfoFormFields}
				icon={AccountCircleOutlinedIcon}
				isOaiNotification={isOaiNotification}
				setIsOpenDetail={setIsOpenDetail}
				title="Personal Information"
				updateNotification={updateNotification}
			/>

			<InformationSection
				isSectionEditable
				autoOpen={autoOpen === 'Address'}
				col={2}
				fieldChangeRequest={changeRequestField}
				formFields={addressFormFields}
				icon={HomeOutlinedIcon}
				isOaiNotification={isOaiNotification}
				setIsOpenDetail={setIsOpenDetail}
				title="Address"
				updateNotification={updateNotification}
			/>

			<InformationSection
				col={2}
				formFields={contactInfoFormFields}
				icon={AccountCircleOutlinedIcon}
				title="Contact Information"
			/>

			<EmergencyContact />
		</Box>
	);

	return (
		<>
			{isMobile ? (
				<MobileBottomDrawer
					icon={AccountBoxOutlinedIcon}
					open={isOpenDetail}
					sxTitle={{ fontWeight: '500', fontSize: '16px', color: '#121212DE', letterSpacing: '0.1em' }}
					textAlign="left"
					title={t('GENERAL INFORMATION')}
					onClose={setIsOpenDetail}
				>
					<UserDetailSections />
				</MobileBottomDrawer>
			) : (
				<GenericDrawer
					headerFlag
					HeaderIcon={AccountBoxOutlinedIcon}
					HeaderIconColor="var(--color-primary)"
					drawerColor="rsSecondary.medium"
					drawerOpen={isOpenDetail}
					setDrawerOpen={setIsOpenDetail}
					title={t('GENERAL INFORMATION')}
					width={498}
				>
					<UserDetailSections />
				</GenericDrawer>
			)}
		</>
	);
};

export default UserDetailDrawer;
