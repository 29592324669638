import React, { useEffect, useState } from 'react';
import LoginBlumeView from '@worklist-2/patientPortal/src/views/LoginBlumeView/index.jsx';

import { useAuth } from '@rs-core/context/UserAuthContext';
import { Navigate } from 'react-router-dom';
import ExternalImageViewerView from '@worklist-2/patientPortal/src/routes/ExternalImageViewerView/ExternalImageViewerView';
import ExternalFormsView from '@worklist-2/patientPortal/src/routes/ExternalFormsView/ExternalFormsView';

function Login() {
	const { loggedIn } = useAuth();
	const [isSharedAndVerified, setIsSharedAndVerified] = useState(false);
	const [isExternalFormAndVerified, setIsExternalFormAndVerified] = useState(false);
	const [isVerifiedButNotShared, setIsVerifiedButNotShared] = useState(false);

	const isSharedStudy =
		location.pathname === '/iv' ||
		location.pathname === '/imageviewerexternal' ||
		location.pathname.startsWith('/externalImageViewer');

	const isExternalForm = location.pathname === '/external-form' || location.pathname.startsWith('/external-form');

	const signup = location.pathname.startsWith('/signup');

	useEffect(() => {
		if (loggedIn) {
			isSharedStudy ? setIsSharedAndVerified(true) : setIsVerifiedButNotShared(true);
		}

		if (isSharedStudy && loggedIn) {
			setIsSharedAndVerified(true);
		}

		if (isExternalForm && loggedIn) {
			setIsExternalFormAndVerified(true);
		}

		if (signup && loggedIn) {
			setIsVerifiedButNotShared(true);
		}
	}, [loggedIn]);

	if (isSharedAndVerified) {
		return <ExternalImageViewerView />;
	}

	if (isExternalFormAndVerified) {
		return <ExternalFormsView />;
	}

	return <>{isVerifiedButNotShared ? <Navigate replace to="/home" /> : <LoginBlumeView />}</>;
}

export default Login;
