import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	HelpBadge,
	HelpCard,
	HelpListItem,
} from '@worklist-2/patientPortal/src/views/HelpView/HelpCenter/patientHelpStyles';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classnames from 'classnames';
import _ from 'lodash';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const PatientPortalHelpCard = ({
	category,
	index,
	data,
	onClick,
	className,
	containerStyle,
	isListItem,
	isGlobalSearch,
	active,
	titleOnly,
}) => {
	const { t } = useTranslation('helpCenter');

	const contentClass = useMemo(() => {
		return `content ${category?.name?.replaceAll(' ', '-')}-${index}`;
	}, [category, index]);

	useEffect(() => {
		// this extracts the string content from the mdx component
		// so that it can be rendered and then ellipsized.
		if (!isGlobalSearch) {
			const content = document.querySelector(`.${contentClass}`.replaceAll(' ', '.'));
			if (content?.hasChildNodes()) {
				let htmlString = '';
				const reg = /<\/?[^>]+(>|$)/g;
				for (let i = 1; i < content.children.length; i++) {
					const element = content.children[i];
					htmlString += element.innerHTML;
				}
				content.innerHTML = content.children[0]?.outerHTML;
				content.outerHTML = `<div>
			    ${content.children[0]?.outerHTML}
			    ${!isListItem ? `<p>${htmlString.replace(reg, '')}</p>` : ''}
			</div>`;
			}
		}
	}, [data, category, contentClass]);

	const getContent = dataInput => {
		try {
			const articleContent = dataInput.fileContent?.split('?');
			const headContent = articleContent[0] + '?';
			const eTag = JSON.parse(dataInput.eTag);
			const bodyStr = _.replace(eTag.Content, /[\[\]-]/g, '').replaceAll('</em>', '');
			const bodyArr = bodyStr?.split('?');
			const headETag = bodyArr[0] + '?';

			let bodyContent = bodyStr;
			if (headContent === headETag.replaceAll('<em>', '')) {
				bodyContent = bodyArr.slice(1).join('?');
			}

			if (bodyContent.includes('<em>')) {
				bodyContent = bodyContent.replace(/\s+/g, ' ');
				const bodyContentArr = bodyContent?.split('<em>');
				const bodyContentFirst = bodyContentArr[0];
				const bodyContentLast = bodyContentArr.slice(1).join('');

				let bodyContentFirstDisplay =
					325 - bodyContentLast.length > 0
						? bodyContentFirst.substring(bodyContentFirst.length - (325 - bodyContentLast.length))
						: '';

				bodyContent = bodyContentFirstDisplay?.split(' ').slice(1).join(' ') + bodyContentLast;
			}

			return (
				<>
					<h3 data-testid="head">{headContent}</h3>
					<p>{bodyContent}</p>
				</>
			);
		} catch (error) {
			return null;
		}
	};

	return isListItem ? (
		<HelpListItem data-testid="help-list-item" role="listitem" onClick={onClick}>
			<StickyNote2OutlinedIcon />
			<div className={contentClass}>{data?.content}</div>
			<ChevronRightIcon />
		</HelpListItem>
	) : (
		<HelpCard
			className={classnames('patient-help-card', active && 'patient-help-card-active')}
			style={{
				flex: 'none',
				minHeight: titleOnly ? '123px' : 'inherit',
				padding: 0,
				...containerStyle,
			}}
			onClick={onClick}
		>
			<Box
				className={`footer result-item ${className}`}
				sx={{
					'& div:nth-child(2)': {
						'*': {
							display: 'block !important',
						},

						h3: {
							display: 'block !important',
						},

						h2: {
							display: 'block !important',
						},

						div: {
							display: 'block !important',
						},
					},
				}}
			>
				<StickyNote2OutlinedIcon
					id="StickyNote"
					style={{
						float: 'left',
						color: '#726f6f',
						margin: '-3px 10px 0px 0px',
					}}
				/>

				<div id="indicator" className="indicator">
					<HelpBadge>
						<span>{t(data?.catName?.replace('-', ' ') || category?.name)}</span>
					</HelpBadge>
				</div>
				<div id="content" className={isGlobalSearch ? '' : contentClass}>
					{!category ? getContent(data) : data?.content}
				</div>
			</Box>
		</HelpCard>
	);
};

PatientPortalHelpCard.propTypes = {
	category: PropTypes.object,
	data: PropTypes.object.isRequired,
	index: PropTypes.number,
	onClick: PropTypes.func,
	containerStyle: PropTypes.object,
	className: PropTypes.string,
	isListItem: PropTypes.bool,
	titleOnly: PropTypes.bool,
};

export default PatientPortalHelpCard;
