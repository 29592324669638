export default function getFhirSourceNameFromPatient({ firstName, lastName, middleName }) {
	const family = lastName ? lastName.trim() : '';
	const first = `${firstName ? `${firstName.trim()} ` : ''}`;
	const middle = `${middleName ? `${middleName.trim()}` : ''}`;

	return {
		family,
		given: [first, middle],
		prefix: [''],
		suffix: [''],
	};
}
