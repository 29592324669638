/* eslint-disable react/jsx-filename-extension */
import * as yup from 'yup';
import { mediaMobile, mediaTablet } from '@worklist-2/core/src';
import React from 'react';

const CARD_HEIGHT = 150;
const CARD_SPACING = 16;

const notificationType = {
	OAI_PATIENT_SYNC: 'OAI_PATIENT_SYNC',
	OAI_PATIENT_DATA_SYNC: 'OAI_PATIENT_DATA_SYNC',
};

const publicBookingSchema = yup
	.object()
	.shape({
		patient: yup.object().required('Required'),
		facility: yup.object().required('Required').nullable(),
		scanType: yup.object().required('Required'),
		gender: yup.string().required('Required'),
		// todo
		// birthDate: yup.date().required('Required'),
	})
	.required();

const newAppointmentSchema = yup
	.object()
	.shape({
		patient: yup.object().required('Required'),
		facility: yup.object().required('Required').nullable(),
		scanType: yup.object().required('Required'),
	})
	.required();

const menuProps = {
	PaperProps: {
		sx: {
			width: 'min(90%,550px) !important',
			minWidth: 'min(90%,550px) !important',
			maxHeight: '340px !important',
			marginTop: '4px',
			border: '1px solid rgba(255, 255, 255, 0.1)',
			boxShadow: '2px 2px 8px 2px rgba(18, 18, 18, 0.08)',
			borderRadius: '6px',
			'& .MuiMenuItem-root.Mui-selected': {
				backgroundColor: '#00000010 !important',
			},
			[mediaTablet]: {
				minWidth: '324px !important',
			},
			[mediaMobile]: {
				minWidth: 'calc(100% - 36px) !important',
			},
		},
	},
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'right',
	},
};

const inputStyles = {
	height: '40px',
	borderRadius: '4px',
	width: 'min(550px,100%)',
	background: '#ffffff',
};

const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const ScanTypeIcon = () => (
	<svg fill="none" height="20" viewBox="0 0 18 20" width="18">
		<path
			d="M9.66683 9.16675H4.66683M6.3335 12.5001H4.66683M11.3335 5.83341H4.66683M14.6668 8.75008V5.66675C14.6668 4.26662 14.6668 3.56655 14.3943 3.03177C14.1547 2.56137 13.7722 2.17892 13.3018 1.93923C12.767 1.66675 12.067 1.66675 10.6668 1.66675H5.3335C3.93336 1.66675 3.2333 1.66675 2.69852 1.93923C2.22811 2.17892 1.84566 2.56137 1.60598 3.03177C1.3335 3.56655 1.3335 4.26662 1.3335 5.66675V14.3334C1.3335 15.7335 1.3335 16.4336 1.60598 16.9684C1.84566 17.4388 2.22811 17.8212 2.69852 18.0609C3.2333 18.3334 3.93336 18.3334 5.3335 18.3334H7.5835M16.3335 18.3334L15.0835 17.0834M15.9168 15.0001C15.9168 16.6109 14.611 17.9167 13.0002 17.9167C11.3893 17.9167 10.0835 16.6109 10.0835 15.0001C10.0835 13.3893 11.3893 12.0834 13.0002 12.0834C14.611 12.0834 15.9168 13.3893 15.9168 15.0001Z"
			stroke="#697586"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.67"
		/>
	</svg>
);

const calendarStyles = {
	'& .MuiCalendarPicker-root': {
		width: 'min(550px,100%)',
		maxHeight: '500px',
		overflow: 'hidden',
		background: '#ffffff',
		border: '1px solid #EAECF0',
		borderRadius: '8px',
		margin: '0px 0px',
	},
	'& .PrivatePickersFadeTransitionGroup-root': {
		fontSize: '18px',
		color: '#344054',
		'& > div > div': {
			justifyContent: 'space-evenly',
		},
	},
	'& .PrivatePickersSlideTransition-root': {
		minHeight: '280px',
		'& > div': {
			'& > div': {
				justifyContent: 'space-evenly',
				paddingBottom: '6px',
			},
		},
	},
};

export {
	DAYS,
	MONTHS,
	CARD_HEIGHT,
	CARD_SPACING,
	notificationType,
	publicBookingSchema,
	newAppointmentSchema,
	menuProps,
	ScanTypeIcon,
	inputStyles,
	calendarStyles,
};
