import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from '@rs-core/context/SelectorContext';
import { markdownContainer, subtitle } from './newsStyles';

const ChangeLog = ({ isPatientView }) => {
	const { language } = useSelector();
	const patientNewsContentEn = require('../../../../../markdown/patient-portal/news/en/changeLog.mdx');
	const patientNewsContentFr = require('../../../../../markdown/patient-portal/news/fr/changeLog.mdx');
	const omegaNewsContentEn = require('../../../../../markdown/omega-ai/news/en/changeLog.mdx');
	const omegaNewsContentFr = require('../../../../../markdown/omega-ai/news/fr/changeLog.mdx');
	const Content = useMemo(() => {
		let content = '';
		switch (language) {
			case 'fr': {
				content = isPatientView ? patientNewsContentFr : omegaNewsContentFr;
				break;
			}
			default: {
				content = isPatientView ? patientNewsContentEn : omegaNewsContentEn;
				break;
			}
		}

		return {
			header: content.default().props.children?.slice(0, 3),
			body: content.default().props.children?.slice(3, content.default().props.children.length),
		};
	}, [isPatientView]);

	return (
		<Box sx={markdownContainer}>
			<Box className="md-header">{Content.header}</Box>
			<Box className="invisible-scrollbar" sx={subtitle}>
				{Content.body}
			</Box>
		</Box>
	);
};

ChangeLog.propTypes = {
	isPatientView: PropTypes.bool,
};

export default ChangeLog;
