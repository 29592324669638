import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useScheduleStore } from '@worklist-2/patientPortal/src/stores';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import searchIcon from '@worklist-2/ui/src/assets/img/homepage/search.png';
import FormAPIAutocompleteVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import { InputAdornment, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { debounce } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { useIsMobile, useIsTablet } from '@worklist-2/core/src';
import QuestionMarkRounded from '@mui/icons-material/QuestionMarkRounded';
import { KeyboardArrowDown } from '@mui/icons-material';

const useStyles = makeStyles()(() => ({
	backdrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(0, 0, 30, 0.4)',
	},
	dialog: {
		position: 'fixed',
		left: '50%',
		transform: 'translateX(-50%)',
		bottom: '0',
		width: '100%',
		height: '230px',
		maxWidth: '100%',
		margin: 0,
	},
}));

const OrderSetScreen = ({ form }, ref) => {
	const [searchParams] = useSearchParams();
	const orgId = searchParams.get('orgId');
	const [orderSetOptions, setOrderSetOptions] = useState('');
	const { t } = useTranslation('scheduling');
	const { getValues, setValue, watch } = form;
	const nextStep = useScheduleStore(state => state.nextStep);
	const getOrgOrderSets = useScheduleStore(state => state.getOrgOrderSets);
	const orderSets = useScheduleStore(state => state.orderSets);
	const setModalityCode = useScheduleStore(state => state.setModalityCode);
	const orgDetails = useScheduleStore(state => state.orgDetails);
	const [dialogOpen, setDialogOpen] = useState(true);
	const [tooltipOpen, setOpenTooltip] = useState(false);
	const isMobile = useIsMobile();
	const isTab = useIsTablet();
	const { classes } = useStyles();
	watch();

	useEffect(() => {
		if (orderSets?.length || !orgId) return;
		getOrgOrderSets({ orgId, searchString: '' });
	}, []);

	useEffect(() => {
		setOrderSetOptions(
			orderSets?.map(orderset => ({
				description: orderset.description,
				studyType: orderset.studyType,
				name: `${orderset.studyType} - ${orderset.description}`,
			}))
		);
	}, [orderSets]);

	const closeDialog = () => {
		setDialogOpen(false);
	};

	const switchApp = () => {
		window.open(orgDetails.deepLink, '_blank');
	};

	const setOrderSetOption = option => {
		if (option) {
			setValue('description', option?.description);
			setValue('orderSetId', option?.studyType);
			const modalityCode = orderSets.find(item => item.studyType === option?.studyType)?.modality.code;
			setModalityCode(modalityCode);
		} else {
			setValue('description', '');
			setValue('orderSetId', '');
			setModalityCode('');
		}
	};

	const gotToNextStep = useCallback(() => {
		nextStep();
	}, [nextStep]);

	const filterOptions = createFilterOptions();

	const searchOrderSets = debounce(async query => {
		getOrgOrderSets({ orgId, searchString: query });
	}, 500);

	const getOrderSetLabel = option => (option?.name ? option?.name : '');

	const openToolTip = () => {
		setOpenTooltip(true);
	};

	const closeToolTip = () => {
		setOpenTooltip(false);
	};

	return (
		<Box>
			<Box
				ref={ref}
				borderRadius="8px"
				display="flex"
				flexDirection="column"
				maxWidth={{ md: '700px' }}
				padding={{
					xs: '20px 16px',
					md: '32px 32px 36px 32px',
				}}
				sx={{
					margin: 'auto',
					background: '#fff',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
				}}
				width={{ xs: '80%' }}
			>
				<Typography
					alignItems="center"
					color="#475467"
					component="label"
					display="flex"
					fontSize={{ xs: '14px', md: '16px' }}
					fontWeight={500}
					lineHeight={{ xs: '16px', md: '18px' }}
					sx={{ marginBottom: '8px' }}
				>
					{t('Search Imaging Procedure')}
					{isTab ? (
						<ClickAwayListener onClickAway={closeToolTip}>
							<Tooltip
								disableFocusListener
								disableHoverListener
								disableTouchListener
								componentsProps={{
									tooltip: {
										sx: {
											background: '#F2F4F7',
											color: '#475467',
											border: '1px solid #D0D5DD',
											fontSize: '14px',
											fontWeight: 400,
											py: 1,
											whiteSpace: 'nowrap',
											minWidth: 'fit-content',
										},
									},
								}}
								open={tooltipOpen}
								title={t('Please refer to the order form provided by your doctor.')}
								onClose={closeToolTip}
							>
								<QuestionMarkRounded
									sx={{
										ml: 1.5,
										border: '1.5px solid #475467',
										borderRadius: '100%',
										fontSize: '12px',
										p: 0.1,
									}}
									onClick={openToolTip}
								/>
							</Tooltip>
						</ClickAwayListener>
					) : (
						<Tooltip
							componentsProps={{
								tooltip: {
									sx: {
										background: '#F2F4F7',
										color: '#475467',
										border: '1px solid #D0D5DD',
										fontSize: '14px',
										fontWeight: 400,
										py: 1,
										whiteSpace: 'nowrap',
										minWidth: 'fit-content',
									},
								},
							}}
							title={t('Please refer to the order form provided by your doctor.')}
						>
							<QuestionMarkRounded
								sx={{
									ml: 1.5,
									border: '1.5px solid #475467',
									borderRadius: '100%',
									fontSize: '12px',
									p: 0.1,
								}}
							/>
						</Tooltip>
					)}
				</Typography>
				<FormAPIAutocompleteVariant
					TextFieldProps={{
						placeholder: t('Search by name/body part/procedure code or Choose from drop down'),
						sx: {
							maxHeight: '40px',
							padding: '0px 0px !important',
							borderRadius: '8px',
							borderColor: '#D0D5DD',
							boxShadow: '0px 1px 2px #1018280D',
							'.MuiAutocomplete-input': {
								padding: {
									sx: '7.5px 32px 7.5px 6px',
								},
							},
						},
						'data-testid': 'order-set-select',
						InputProps: {
							startAdornment: (
								<InputAdornment position="start" sx={{ ml: 1.6 }}>
									<img alt="search" height="20px" src={searchIcon} />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end" sx={{ mx: 1 }}>
									<KeyboardArrowDown sx={{ color: 'black' }} />
								</InputAdornment>
							),
						},
					}}
					formHook={form}
					// hideClear={
					// 	Boolean(form.getValues('userLocation')?.place_id === 'current_location') ||
					// 	!form.getValues('userLocation')
					// }
					name="orderSet"
					options={orderSetOptions}
					props={{
						isOptionEqualToValue: (option, value) => !value || option?.studyType === value?.studyType,
						renderOption: (props, option) => (
							<li {...props} key={option?.studyType}>
								<Typography
									fontSize={{ sx: '12px', md: '16px' }}
									lineHeight={{ sx: '18px', md: '24px' }}
									sx={{
										color: '#364152',
									}}
								>
									{option?.name}
								</Typography>
							</li>
						),
						getOptionLabel: getOrderSetLabel,
						filterOptions: (options, state) => {
							const results = filterOptions(options, state);
							return results;
						},
					}}
					setOptions={setOrderSetOptions}
					sx={{
						height: '40px',
						borderRadius: '4px',
						width: '100%',
						background: '#ffffff',
						fontSize: 14,
					}}
					onChange={setOrderSetOption}
					onSearch={searchOrderSets}
				/>
				<Typography
					sx={{
						color: '#344054',
						fontSize: '12px',
						lineHeight: '16px',
						fontWeight: 400,
						margin: { xs: '12px 0 0', md: '12px 0 20px' },
					}}
				>
					e.g. CT Abdomen Pelvis with Contrast
				</Typography>
				<Button
					color="info"
					data-testid="bookslot-next-btn"
					disabled={!getValues().description}
					sx={{
						backgroundColor: '#42A5F5',
						marginTop: '20px',
						width: '100%',
						display: {
							xs: 'none',
							md: 'block',
						},
					}}
					type="button"
					variant="contained"
					onClick={gotToNextStep}
				>
					{t('NEXT')}
				</Button>
			</Box>
			<Button
				color="info"
				data-testid="bookslot-next-btn"
				disabled={!getValues().description}
				sx={{
					position: 'fixed',
					bottom: '32px',
					width: '90%',
					left: '5%',
					backgroundColor: '#42A5F5',
					marginTop: '20px',
					display: { md: 'none' },
				}}
				type="button"
				variant="contained"
				onClick={gotToNextStep}
			>
				{t('NEXT')}
			</Button>
			{isMobile && orgDetails?.deepLink && (
				<Dialog
					BackdropProps={{ classes: { root: classes.backdrop } }}
					PaperProps={{ width: '100%' }}
					classes={{ paper: classes.dialog }}
					open={dialogOpen}
				>
					<DialogContent
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							padding: '32px 20px',
						}}
					>
						<Box>
							<Typography
								sx={{
									fontFamily: 'Roboto',
									fontSize: '16px',
									fontWeight: '500',
									color: 'rgba(16, 24, 40, 1)',
								}}
							>
								Experience is better on the app
							</Typography>
							<Typography
								sx={{
									color: 'rgba(52, 64, 84, 1)',
									fontFamily: 'Roboto',
									fontSize: '14px',
									fontWeight: '400',
								}}
							>
								Never miss an update on your appointments. Keep track of your health scans. Open this in
								the Blume app to get the full experience
							</Typography>
						</Box>

						<Box
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Button
								data-testid="not-now"
								sx={{
									width: '120px',
									height: '42px',
									borderRadius: '8px',
									border: '2px solid #42A5F5',
									color: '#42A5F5',
									fontSize: '16px',
								}}
								variant="outlined"
								onClick={closeDialog}
							>
								Not now
							</Button>
							<Button
								sx={{
									width: '190px',
									height: '42px',
									borderRadius: '8px',
									backgroundColor: '#42A5F5',
									fontSize: '16px',
									padding: '12px 14px',
								}}
								variant="contained"
								onClick={switchApp}
							>
								Switch to the app
							</Button>
						</Box>
					</DialogContent>
				</Dialog>
			)}
		</Box>
	);
};

export default forwardRef(OrderSetScreen);
