import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import React, { createRef, useEffect, useState } from 'react';
import CustomTextInput from '@rs-ui/components/Help/CustomTextInput';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import PatientHelpSearchSuggestions from '@worklist-2/patientPortal/src/views/HelpView/HelpCenter/PatientHelpSearchSuggestions';
import { SearchWrapper } from '@worklist-2/patientPortal/src/views/HelpView/HelpCenter/patientHelpStyles';
import useHelpCenter from '@rs-core/hooks/useHelpCenter';
import { useTranslation } from 'react-i18next';

const sugRef = createRef();

const PatientHelpSearchInput = ({ formStyle, inputStyle, onChange, onSubmit, isPip, onSelect }) => {
	const { handleQuestionChange } = useHelpCenter();
	const [keyword, setKeyword] = useState('');
	const { t } = useTranslation('helpCenter');

	useEffect(() => {
		if (onChange) {
			onChange(keyword);
		}
		if (!sugRef.current.isShowing() && keyword) {
			sugRef.current.toggle(true, isPip);
		}
		handleQuestionChange(keyword);
	}, [keyword]);

	const handleSubmit = e => {
		e.preventDefault();
		sugRef.current?.fetchAll();
	};

	useEffect(() => {
		document.addEventListener('click', event => {
			try {
				const ignoreClickOnMeElement = document.getElementById('container');
				const isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
				sugRef.current?.toggle(isClickInsideElement, isPip);
			} catch (error) {
				console.error(error);
			}
		});
	}, []);

	return (
		<SearchWrapper>
			<div id="container">
				<Paper
					component="form"
					style={formStyle}
					sx={{
						p: '2px',
						display: 'flex',
						alignItems: 'center',
						width: 603,
						height: 50,
						background: '#FFF',
						marginBottom: '19px',
						borderRadius: 10,
						margin: 'auto',
						boxShadow: 'none',
						borderWidth: 1,
						borderColor: 'rgba(112, 112, 112, 0.15)',
					}}
					onSubmit={onSubmit || handleSubmit}
				>
					<SearchIcon sx={{ m: '10px', color: '#121212', marginLeft: '19px' }} />
					<CustomTextInput
						data-testid="PatientHelpSearchInput"
						inputProps={{ 'aria-label': 'Search' }}
						placeholder={t('Search help articles')}
						style={{
							...inputStyle,
							flex: 1,
						}}
						sx={{ ml: -1, flex: 1 }}
						value={keyword}
						onChange={e => setKeyword(e.target.value)}
					/>

					{keyword && (
						<IconButton
							data-testid="PatientHelpSearchInputKeyword"
							sx={{ m: '10px', marginLeft: '19px' }}
							onClick={() => setKeyword('')}
						>
							<CloseOutlinedIcon sx={{ color: '#121212' }} />
						</IconButton>
					)}
				</Paper>
			</div>
			<PatientHelpSearchSuggestions ref={sugRef} onSelect={onSelect} />
		</SearchWrapper>
	);
};

export default PatientHelpSearchInput;
