import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { getUserInitials } from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/utils';

const EmergencyContactCard = ({ contactInfo }) => (
	<Grid container item sx={{}}>
		<Grid item md={2} xs={3}>
			<Avatar
				sx={{ width: '54px', height: '54px', background: '#CAA0FF', borderRadius: '10px' }}
				variant="rounded"
			>
				{contactInfo?.name && getUserInitials(contactInfo.name)}
			</Avatar>
		</Grid>

		<Grid
			item
			md={10}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				rowGap: '5px',
			}}
			xs={9}
		>
			<Typography
				component="span"
				sx={{
					color: '#121212DE',
					fontSize: '14px',
					lineHeight: '16px',
					fontWeight: '700',
					textTransform: 'capitalize',
				}}
			>
				{contactInfo.name}
			</Typography>
			<Typography
				component="span"
				sx={{
					lineHeight: '14px',
					fontSize: '12px',
				}}
			>
				{contactInfo.email}
			</Typography>
			<Typography
				component="span"
				sx={{
					lineHeight: '14px',
					fontSize: '12px',
				}}
			>
				{contactInfo.phone}
			</Typography>
		</Grid>
	</Grid>
);

export default EmergencyContactCard;
