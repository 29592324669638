// core
import React, { useEffect, useState } from 'react';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

// components
import FormFieldVariants from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/FormFieldVariants';

// api
import getValueSetByName from '@worklist-2/patientPortal/src/api/getValueSetByName';

//utils
import formValidationSchema from '@worklist-2/patientPortal/src/views/NewSettingsView/validationSchemas/formValidationSchema';

const formTypes = {
	myPhysician: {
		fields: [
			{
				name: 'name',
				type: 'text',
				label: 'Physician Name',
				placeholder: '',
				required: true,
			},

			{
				name: 'specialisation',
				type: 'text',
				label: 'Specialization',
				placeholder: '',
				required: true,
			},

			{
				name: 'phone',
				type: 'muiPhone',
				label: 'Phone',
				placeholder: '',
				required: true,
			},

			{
				name: `email`,
				type: 'text',
				label: 'Email',
				placeholder: '',
				required: true,
			},
		],

		defaultValues: {
			name: '',
			specialisation: '',
			phone: '+1',
			email: '',
		},

		message: 'Physician with same Email Id already exists',
	},

	emergencyContact: {
		fields: [
			{
				name: 'name',
				type: 'text',
				label: 'Name',
				placeholder: '',
				required: true,
			},

			{
				label: 'Relation',
				name: 'relation',
				type: 'select',
				selectOptions: ['Parent', 'Spouse', 'Child', 'Friend', 'Family', 'Others'],
				required: true,
			},

			{
				name: 'phone',
				type: 'muiPhone',
				label: 'Phone',
				placeholder: '',
				required: true,
			},

			{
				name: `email`,
				type: 'text',
				label: 'Email',
				placeholder: '',
				required: false,
			},
		],

		defaultValues: {
			name: '',
			phone: '+1',
			email: '',
			relation: '',
		},

		message: 'Emergency Contact with same Email Id already exists',
	},
};

export const CancelAddButtons = ({ onCancel, onSave, handleSubmit, t, isEdit }) => {
	const [isClicked, setClicked] = useState(false);
	const typoStyle = {
		fontSize: '16px',
		fontStyle: 'medium',
		fontWeight: '500',
		lineHeight: '18.75px',
		letterSpacing: '0.15em',
	};

	const buttonsx = { width: '100px', height: '42px', borderRadius: '8px' };

	const onSubmit = () => {
		setClicked(true);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			<Box>
				<Button
					// color="rsPrimary"
					data-cy="CancelSave"
					sx={{
						border: '2px solid #42A5F5',
						...buttonsx,
					}}
					variant="outlined"
					onClick={() => {
						onCancel();
					}}
				>
					<Typography sx={{ ...typoStyle, letterSpacing: 0.5 }}>{t('CANCEL')}</Typography>
				</Button>
			</Box>
			<Box onClick={onSubmit}>
				<LoadingButton
					data-cy="ADD"
					loading={isClicked}
					sx={{ ...buttonsx, backgroundColor: '#42A5F5' }}
					variant="contained"
					onClick={handleSubmit(onSave)}
				>
					<Typography sx={typoStyle}>{isEdit ? t('UPDATE') : t('ADD')}</Typography>
				</LoadingButton>
			</Box>
		</Box>
	);
};

const GenerateForm = ({ formType, onSave, onCancel, formData, existsMsg, setExistsMsg, isOpen, message }) => {
	const [emergencyContactRelationSelectValues, setEmergencyContactRelationSelectValues] = useState([]);
	if (emergencyContactRelationSelectValues?.length > 0)
		formTypes.emergencyContact.fields[1].selectOptions = emergencyContactRelationSelectValues;
	const { t } = useTranslation('drawer');
	const isEdit = formData && Object.keys(formData).length > 0;
	const formFields = formTypes[formType].fields;
	const __config = useConfig();

	const form = useForm({
		resolver: yupResolver(formValidationSchema(formType)),
		defaultValues: isEdit ? formData : formTypes[formType].defaultValues,
	});

	useEffect(() => {
		const fetchEmergencyContactRelationSelectValues = async () => {
			const values = await getValueSetByName({ __config, name: 'relationship' });
			setEmergencyContactRelationSelectValues(values);
		};

		fetchEmergencyContactRelationSelectValues();
	}, []);

	useEffect(() => {
		if (!isOpen) {
			form.reset();
			!!form.formState.errors && form.clearErrors();
			if (existsMsg) {
				setExistsMsg(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: '100%',
				padding: '0px 24px 24px 24px',
			}}
		>
			<Stack flexGrow={1} spacing="23px" sx={{ padding: '22px 0px', overflow: 'auto' }}>
				{formFields.map((fieldData, idx) => (
					<FormFieldVariants
						key={idx}
						field={{
							name: fieldData.name,
							type: fieldData.type,
							options: fieldData.selectOptions?.map(option => t(option)),
							props: {
								defaultCountry: 'ca',
								SelectProps: {
									renderValue: item => item,
								},

								label: t(fieldData.label),
								helperText:
									t(form.formState.errors[fieldData.name]?.message) || fieldData.required
										? t('Required')
										: '',
								placeholder: fieldData.placeholder,
								InputProps: {
									autoComplete: 'off',
								},
							},
						}}
						formHook={form}
					/>
				))}

				<Box
					sx={{
						color: 'red',
						visibility: existsMsg === true ? 'visible' : 'hidden',
					}}
				>
					{t(formTypes[formType].message)}
				</Box>
				<div
					style={{
						paddingTop: '20px',
						paddingLeft: '10px',
						color: 'red',
						paddingBottom: '10px',
						visibility: message ? 'visible' : 'hidden',
					}}
				>
					{t('Contact with same Email Id already exists')}
				</div>
			</Stack>

			<CancelAddButtons
				handleSubmit={form.handleSubmit}
				isEdit={isEdit}
				reset={form.reset}
				t={t}
				onCancel={onCancel}
				onSave={onSave}
			/>
		</Box>
	);
};

export default GenerateForm;
