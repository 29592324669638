import { useEffect } from 'react';
import _ from 'lodash';

import { useConfig } from '@rs-core/context/ConfigContext';

import { useShareDrawerStore } from '@worklist-2/patientPortal/src/stores/share-drawer';
import { useFormStore } from '@worklist-2/patientPortal/src/stores/forms';
import { useAppointmentStore } from '@worklist-2/patientPortal/src/stores/appointment';
import { useScheduleStore } from '@worklist-2/patientPortal/src/stores/schedule';
import { useLicenseStore } from '@worklist-2/patientPortal/src/stores/license';

const useStoreSync = () => {
	const __config = useConfig();
	const setShareDrawerSharedStates = useShareDrawerStore(state => state.setSharedStates);
	const setScheduleSharedStates = useScheduleStore(state => state.setSharedStates);
	const setAppointmentSharedStates = useAppointmentStore(state => state.setSharedStates);
	const setFormSharedStates = useFormStore(state => state.setSharedStates);
	const setLicenseSharedStates = useLicenseStore(state => state.setSharedStates);

	useEffect(() => {
		// Extracts only necessary configuration data to save memory
		const filteredConfig = _.pick(__config, ['data_sources', 'patient_portal']);

		setShareDrawerSharedStates({ __config: filteredConfig });
		setScheduleSharedStates({ __config: filteredConfig });
		setAppointmentSharedStates({ __config: filteredConfig });
		setFormSharedStates({ __config: filteredConfig });
		setLicenseSharedStates({ __config: filteredConfig });
	}, [
		__config,
		setAppointmentSharedStates,
		setFormSharedStates,
		setLicenseSharedStates,
		setScheduleSharedStates,
		setShareDrawerSharedStates,
	]);
};

export default useStoreSync;
