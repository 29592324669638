import React from 'react';
import ImageViewerExternal from '@rs-ui/views/ImageViewerView3D/ImageViewerExternal/ImageViewerExternal';
import Box from '@mui/material/Box';

export default function ExternalImageViewerView() {
	function useQueryParams() {
		const params = new URLSearchParams(window ? window.location.search : {});

		return new Proxy(params, {
			get(target, prop) {
				return target.get(prop);
			},
		});
	}

	const { internalManagingOrganizationID, studyInstanceUID, creatorUserId, isUploaded, isMobile, oAiAuthToken } =
		useQueryParams();

	const handledCreatorId = creatorUserId === null || creatorUserId === 'null' ? '' : creatorUserId;

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
			}}
		>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					position: 'relative',
				}}
			>
				<ImageViewerExternal
					disabledKeyImages
					blumeToken={oAiAuthToken}
					creatorUserId={handledCreatorId}
					internalManagingOrganizationID={+internalManagingOrganizationID}
					isMobile={isMobile === 'true'}
					isUploaded={isUploaded === 'true'}
					studyInstanceUID={studyInstanceUID}
					sx={{
						width: '100vw',
						height: '100vh',
						borderRadius: 0,
					}}
				/>

				<Box
					sx={{
						position: 'absolute',
						top: '20px',
						left: '0',
					}}
				/>
			</Box>
		</Box>
	);
}
