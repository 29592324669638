// React core
import React, { useEffect, useState } from 'react';

// MUI
import { Button, Box, InputAdornment, TextField, Grid } from '@mui/material';

// Icons
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

// Components
import GenericDrawer from '@rs-ui/components/Drawers/GenericDrawer/GenericDrawer';
import { useTranslation } from 'react-i18next';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';

// Utils
import { useIsMobile } from '@rs-core/utils/responsiveUtils';

// context
import { useSettingsViewContext } from '@worklist-2/patientPortal/src/context/SettingsViewContext';

const UserDetailSections = ({
	t,
	height,
	weight,
	handleHeightChange,
	handleWeightChange,
	setIsOpenDetail,
	handleOnSave,
	fieldChangeRequest,
}) => (
	<Box
		sx={{
			pl: '27px',
			pr: '20px',
			pt: '15px',
		}}
	>
		<Grid container columnSpacing={1} sx={{ marginTop: '10px' }}>
			<Grid item lg={6} sm={6} xs={12}>
				<TextField
					InputLabelProps={{ shrink: true }}
					InputProps={{
						endAdornment: <InputAdornment position="end">cm</InputAdornment>,
					}}
					error={Boolean(!!fieldChangeRequest && fieldChangeRequest?.toLowerCase() === 'height')}
					helperText={
						!!fieldChangeRequest && fieldChangeRequest?.toLowerCase() === 'height'
							? t('Fill in the missing info')
							: ''
					}
					id="bmi-height"
					label="Height"
					value={height}
					onChange={handleHeightChange}
				/>
			</Grid>

			<Grid item lg={6} sm={6} xs={12}>
				<TextField
					InputLabelProps={{ shrink: true }}
					InputProps={{
						endAdornment: <InputAdornment position="end">kg</InputAdornment>,
					}}
					error={Boolean(!!fieldChangeRequest && fieldChangeRequest?.toLowerCase() === 'weight')}
					helperText={
						!!fieldChangeRequest && fieldChangeRequest?.toLowerCase() === 'weight'
							? t('Fill in the missing info')
							: ''
					}
					id="bmi-weight"
					label="Weight"
					value={weight}
					onChange={handleWeightChange}
				/>
			</Grid>
		</Grid>
		<Box
			sx={{
				marginTop: '30px',
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			<Button
				sx={{
					color: 'var(--color-primary)',
					borderColor: 'var(--color-primary)',
					borderRadius: '8px',
					width: '100px',
					fontWeight: '400',
					letterSpacing: '0.1em',
				}}
				variant="outlined"
				onClick={() => setIsOpenDetail(false)}
			>
				{t('Cancel')}
			</Button>
			<Button
				sx={{
					background: 'var(--color-primary)',
					borderRadius: '8px',
					width: '100px',
					fontWeight: '400',
					letterSpacing: '0.1em',
				}}
				variant="contained"
				onClick={handleOnSave}
			>
				{t('Save')}
			</Button>
		</Box>
	</Box>
);

const UserBmiDetailDrawer = ({
	isOpenDetail,
	setIsOpenDetail,
	isOaiNotification,
	fieldChangeRequest,
	updateNotification,
}) => {
	const { t } = useTranslation('profile');
	const { user, onUserUpdate } = useSettingsViewContext();

	const [height, setHeight] = useState('');
	const [weight, setWeight] = useState('');

	const isMobile = useIsMobile();

	const handleHeightChange = event => {
		setHeight(event?.target?.value);
	};

	const handleWeightChange = event => {
		setWeight(event?.target?.value);
	};

	const handleOnSave = () => {
		onUserUpdate({ weight, height });

		if (isOaiNotification) updateNotification('updated');

		setIsOpenDetail(false);
	};

	useEffect(() => {
		setHeight(user?.height ? user.height : '');
		setWeight(user?.weight ? user.weight : '');
	}, [user]);

	return (
		<>
			{isMobile ? (
				<MobileBottomDrawer
					icon={AccountBoxOutlinedIcon}
					open={isOpenDetail}
					sxTitle={{ fontWeight: '500', fontSize: '16px', color: '#121212DE', letterSpacing: '0.1em' }}
					textAlign="left"
					title={t('BMI')}
					onClose={setIsOpenDetail}
				>
					<UserDetailSections
						fieldChangeRequest={fieldChangeRequest}
						handleHeightChange={handleHeightChange}
						handleOnSave={handleOnSave}
						handleWeightChange={handleWeightChange}
						height={height}
						setIsOpenDetail={setIsOpenDetail}
						t={t}
						weight={weight}
					/>
				</MobileBottomDrawer>
			) : (
				<GenericDrawer
					headerFlag
					HeaderIcon={AccountBoxOutlinedIcon}
					HeaderIconColor="var(--color-primary)"
					drawerColor="rsSecondary.medium"
					drawerOpen={isOpenDetail}
					setDrawerOpen={setIsOpenDetail}
					title={t('BMI')}
					width={498}
				>
					<UserDetailSections
						fieldChangeRequest={fieldChangeRequest}
						handleHeightChange={handleHeightChange}
						handleOnSave={handleOnSave}
						handleWeightChange={handleWeightChange}
						height={height}
						setIsOpenDetail={setIsOpenDetail}
						t={t}
						weight={weight}
					/>
				</GenericDrawer>
			)}
		</>
	);
};

export default UserBmiDetailDrawer;
