import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider, SvgIcon } from '@mui/material';
import PhoneIcon from '@worklist-2/ui/src/assets/icons/phone.svg';

const Navbar = ({ orgName, orgLogoSrc, orgWebsite, phone }) => {
	const websiteLink =
		orgWebsite?.startsWith('https://') || orgWebsite?.startsWith('http://') ? orgWebsite : `https://${orgWebsite}`;

	return (
		<AppBar
			position="static"
			sx={{
				padding: '20px 48px',
				color: '#101828',
				boxShadow: 0,
				background: '#fff',
				borderBottom: '1px solid #dod5dd',
			}}
		>
			<Box
				display="flex"
				flexDirection={{ xs: 'column', md: 'row' }}
				gap={{ xs: '12px' }}
				sx={{ justifyContent: { md: 'center', lg: 'space-between' } }}
			>
				<Box display="flex" gap="16px" justifyContent={{ xs: 'center', md: 'left' }}>
					<Box>
						<svg fill="none" height="24" viewBox="0 0 94 24" width="94">
							<g id="Group">
								<g id="Group_2">
									<path
										d="M28.1763 22.7401V4.09277H36.5814C40.2112 4.09277 41.8869 5.97448 41.8869 8.75481C41.8869 11.0578 40.4348 12.3778 38.927 12.6587C40.5745 13.024 42.4172 14.231 42.4172 17.4051C42.4172 20.5792 40.3509 22.7409 35.8275 22.7409H28.1763V22.7401ZM36.4974 11.9565C38.7034 11.9565 39.8763 10.889 39.8763 8.83925C39.8763 6.98598 38.8431 5.86247 36.2181 5.86247H30.1869V11.9565H36.4974ZM35.9945 21.0272C38.9545 21.0272 40.3783 19.4824 40.3783 17.2638C40.3783 14.7084 38.8422 13.6693 36.5531 13.6693H30.1861V21.0272H35.9945Z"
										fill="#3B96F7"
										id="Vector"
									/>
									<path
										d="M43.7329 22.7398V3.86768H45.6879V22.7398H43.7329Z"
										fill="#3B96F7"
										id="Vector_2"
									/>
									<path
										d="M57.4826 22.7396V19.3415C56.6448 22.0934 54.5227 23.1049 52.4281 23.1049C49.5521 23.1049 47.5688 21.5601 47.5688 17.938V8.92236H49.5238V16.8136C49.5238 20.2401 50.5288 21.4472 53.0706 21.4472C56.5051 21.4472 57.3987 18.442 57.3987 15.3817V8.92236H59.3254V22.7396H57.4826Z"
										fill="#3B96F7"
										id="Vector_3"
									/>
									<path
										d="M78.0649 22.7397V14.0902C78.0649 11.2815 76.8921 10.1864 75.0767 10.1864C73.0661 10.1864 71.6696 11.9837 71.6696 14.1462V22.7397H69.7146V14.1178C69.7146 11.029 68.6815 10.2424 66.699 10.2424C64.0458 10.2424 63.2362 12.7418 63.2362 15.9435V22.7397H61.2812V8.92244H63.124V12.3765C63.9062 9.51176 65.6376 8.55713 67.5078 8.55713C69.5741 8.55713 70.8309 9.39976 71.4169 11.8148C72.0029 9.5962 73.7908 8.55713 75.5779 8.55713C78.2029 8.55713 79.9617 10.102 79.9617 13.5V22.7397H78.0632H78.0649Z"
										fill="#3B96F7"
										id="Vector_4"
									/>
									<path
										d="M93.6712 18.6679C92.889 21.5043 90.7395 23.1051 87.9467 23.1051C83.5903 23.1051 81.4126 20.6057 81.4126 15.5792C81.4126 11.3109 83.73 8.55811 87.7514 8.55811C91.7727 8.55811 93.8665 11.4504 93.8665 16.2814H83.3667C83.4507 19.6794 85.0424 21.5327 87.8627 21.5327C89.6781 21.5327 91.1302 20.4936 91.6322 18.6679H93.6703H93.6712ZM91.8558 14.989C91.8001 11.9838 90.1801 10.1589 87.6948 10.1589C85.2095 10.1589 83.5064 12.0122 83.4224 14.989H91.8558Z"
										fill="#3B96F7"
										id="Vector_5"
									/>
								</g>
								<g id="Group_3">
									<path
										d="M7.85629 6.90547C6.64148 6.74952 6.13517 6.4092 4.88781 5.92154C2.17377 4.86093 0.647128 5.45801 0.158807 6.6625C0.0320148 6.97612 0.474074 8.94312 0.62057 9.23003C4.7199 17.2402 7.24032 19.8473 8.50396 19.3795C8.55193 19.3614 8.60162 19.3511 8.65216 19.3425C9.84127 19.1331 10.8882 14.2195 11.2643 12.6462C11.5684 11.3737 11.8228 9.96496 12.0164 8.77769C12.1895 7.71794 11.3448 6.70472 10.2885 6.86498C9.71962 6.95113 8.87148 7.03643 7.85629 6.90633V6.90547Z"
										fill="#3B96F7"
										id="Vector_6"
									/>
									<path
										d="M8.71598 6.11898C10.3961 6.11898 11.7581 4.7492 11.7581 3.05949C11.7581 1.36978 10.3961 0 8.71598 0C7.03585 0 5.67383 1.36978 5.67383 3.05949C5.67383 4.7492 7.03585 6.11898 8.71598 6.11898Z"
										fill="#3B96F7"
										id="uuid-1962c442-e0b9-4cb7-9e63-60151b9e1f10"
									/>
									<path
										d="M15.01 9.18212C13.0284 7.63385 12.0869 8.99515 6.54744 9.12181C5.32064 9.15024 2.97498 8.82456 2.02576 8.4782C-0.176829 7.67348 0.230962 6.83257 0.127301 6.76709C0.125587 6.76623 0.0716148 6.89547 0.0587642 6.98163C-0.429557 10.2996 2.25364 15.1693 3.36907 17.1466C4.32943 18.85 6.47033 21.9362 8.00897 23.6783C8.39192 24.1117 9.03102 24.1022 9.42767 23.6817C10.5414 22.5014 11.3655 21.1633 12.2839 19.8279C13.6632 17.8238 14.9954 15.1856 15.7621 13.2342C16.5289 11.2827 15.8727 9.85674 15.0082 9.18126L15.01 9.18212Z"
										fill="#94CFFF"
										id="Vector_7"
									/>
								</g>
							</g>
						</svg>
					</Box>
					<Divider
						flexItem
						orientation="vertical"
						sx={{ backgroundColor: '#dadada', mx: 0.5 }}
						variant="fullWidth"
					/>
					<Box
						display="flex"
						style={{ cursor: 'pointer' }}
						onClick={() => window.open(websiteLink, '_blank')}
					>
						{orgLogoSrc ? (
							<img
								alt={orgName}
								data-testid="org-logo"
								height="28px"
								src={URL.createObjectURL(orgLogoSrc)}
							/>
						) : (
							<Typography data-testid="org-name" fontSize="20px" fontWeight={500} lineHeight="24px">
								{orgName}
							</Typography>
						)}
					</Box>
				</Box>
				<Box justifyContent={{ xs: 'center', md: 'left' }} sx={{ display: 'flex' }}>
					{orgWebsite && (
						<Typography
							component="a"
							href={websiteLink}
							rel="noopener"
							sx={{
								fontWeight: 500,
								lineHeight: '24px',
								fontSize: '14px',
								color: '#101828',
								textDecoration: 'none',
							}}
							target="_blank"
						>
							{orgWebsite}
						</Typography>
					)}
					{phone && (
						<>
							<div
								style={{ padding: '0px 8px', marginRight: '12px', borderRight: '2px solid #c4c4c4' }}
							/>
							<Typography
								component="a"
								href={`tel:${phone.trim().replace(/ /g, '')}`}
								rel="noopener"
								sx={{
									fontWeight: 500,
									lineHeight: '24px',
									fontSize: '14px',
									display: 'flex',
									alignItems: 'center',
									color: '#4B5565',
									textDecoration: 'none',
								}}
							>
								<SvgIcon
									component={PhoneIcon}
									sx={{
										width: '16px',
										fill: 'transparent',
										marginRight: '4px',
									}}
								/>
								{phone}
							</Typography>
						</>
					)}
				</Box>
			</Box>
		</AppBar>
	);
};

export default Navbar;
