import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import InputBase from '@mui/material/InputBase';

import { LocalizationProvider } from '@mui/x-date-pickers';

const DatePicker = ({ setDateOfBirth, name, ...props }) => {
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');

	const handleDateChange = newValue => {
		setValue(newValue);
		setDateOfBirth(newValue);
		setInputValue(formatDate(newValue));
	};

	const handleInputChange = event => {
		let newValue = event.target.value;
		const backspacePressed = newValue.length < inputValue.length;

		if (inputValue === '') {
			event.target.placeholder = 'mm/dd/yyyy';
		} else {
			event.target.placeholder = '';
		}

		const firstSlashIndex = 2;
		const secondSlashIndex = 5;
		const existingSlashes = (newValue.match(/\//g) || []).length;

		if (existingSlashes < 2) {
			if (newValue.length > firstSlashIndex && newValue[firstSlashIndex] !== '/') {
				newValue = `${newValue.slice(0, firstSlashIndex)}/${newValue.slice(firstSlashIndex)}`;
			}

			const newExistingSlashes = (newValue.match(/\//g) || []).length;

			if (newExistingSlashes < 2 && newValue.length > secondSlashIndex && newValue[secondSlashIndex] !== '/') {
				newValue = `${newValue.slice(0, secondSlashIndex)}/${newValue.slice(secondSlashIndex)}`;
			}
		}

		if (backspacePressed) {
			if (inputValue.length - newValue.length > 2) {
				newValue = newValue.replace(/\/+/g, '/').replace(/\/$/, '');
				if (newValue.length === 1 && newValue[0] === '/') {
					newValue = '';
				}
			}
		}

		if (newValue.length > 10) {
			newValue = newValue.slice(0, 10);
		}

		const monthPart = newValue.split('/')[0];
		if (monthPart.length === 1) {
			newValue = newValue.replace(/^([0-9])\//, '0$1/');
		}
		const digitsBeforeFirstSlash = newValue.split('/')[0].length;
		if (digitsBeforeFirstSlash > 2) {
			newValue = newValue.slice(0, 2);
		}

		const [month, day] = newValue.split('/');

		if (parseInt(month) > 12) {
			newValue = `12/${day || ''}`;
		}

		if (parseInt(day) > 30) {
			newValue = `${month || ''}/30`;
		}

		setInputValue(newValue);

		if (isValidDate(newValue)) {
			const formattedDate = moment(newValue, 'MM/DD/YYYY', true);
			if (formattedDate.isValid()) {
				setValue(formattedDate.toDate());
				setDateOfBirth(formattedDate.toDate());
			} else {
				handleDateChange(null);
				setInputValue(newValue);
			}
		} else {
			handleDateChange(null);
			setInputValue(newValue);
		}
	};

	const isValidDate = dateString => moment(dateString, 'MM/DD/YYYY', true).isValid();

	const formatDate = input => {
		if (!input) return '';

		return moment(input).format('MM/DD/YYYY');
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<InputBase
				data-testid="binded-data-input"
				placeholder="mm/dd/yyyy"
				sx={{
					position: 'absolute',
					left: '10px',
					top: '33px',
					opacity: '1',
					width: 'calc(100% - 50px)',
					caretColor: '#000',
					zIndex: 10,
				}}
				type="text"
				value={inputValue}
				onChange={handleInputChange}
			/>
			<DesktopDatePicker
				InputAdornmentProps={{ 'data-cy': 'calendar-icon' }}
				InputProps={{ name, 'data-testid': name }}
				inputFormat="mm/dd/yyyy"
				renderInput={params => (
					<TextField
						sx={{
							'.MuiInputBase-input': {
								padding: '8px 12px',
							},

							'.MuiSvgIcon-root': {
								opacity: '1 !important',
							},

							'& fieldset': { border: '1px solid #D0D5DD' },
							'& .MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
								{
									borderColor: '#D0D5DD !important',
									borderWidth: '1px',
								},

							'& input': {
								pointerEvents: 'none !important',
								caretColor: 'transparent',
								opacity: '0',
							},
						}}
						{...params}
					/>
				)}
				value={value}
				onChange={handleDateChange}
				{...props}
			/>
		</LocalizationProvider>
	);
};

export default DatePicker;
