import useHelpCenter from '@rs-core/hooks/useHelpCenter';
import _ from 'lodash';
import React, { createRef, forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import {
	SuggestionsWrapper,
	RecentSection,
	SearchInfoPeek,
} from '@worklist-2/patientPortal/src/views/HelpView/HelpCenter/patientHelpStyles';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useNavigate } from 'react-router';
import { useBlumeHelpCenter } from '@worklist-2/patientPortal/src/context/BlumeHelpCenterContext';
import { useTranslation } from 'react-i18next';

const searchRef = createRef();

const PatientHelpSearchSuggestions = forwardRef((props, ref) => {
	const { searchQuestion } = useHelpCenter();
	const [show, setShow] = useState(false);
	const [isPip, setIsPip] = useState(false);

	useImperativeHandle(ref, () => ({
		isShowing: () => show,
		toggle: (v, boo) => {
			setIsPip(boo);
			if (boo && !v && searchQuestion.trim().length > 0) {
				setShow(true);
			} else {
				setShow(v);
			}
		},
		fetchAll: () => {
			searchRef.current?.showAllResults();
		},
	}));

	return (
		show && (
			<SuggestionsWrapper
				isPip={isPip}
				className={`invisible-scrollbar ${(isPip && searchQuestion) || !isPip ? '' : 'hidden'}`}
			>
				{searchQuestion?.trim() ? (
					<SearchResults
						ref={searchRef}
						isPip={isPip}
						dismiss={() => setShow(false)}
						onSelect={props.onSelect}
					/>
				) : (
					!isPip && <RecentResults />
				)}
			</SuggestionsWrapper>
		)
	);
});

export default PatientHelpSearchSuggestions;

const SearchResults = forwardRef(({ dismiss, isPip, onSelect }, ref) => {
	const { searchQuestion } = useHelpCenter();
	const navigate = useNavigate();
	const [limit, setLimit] = useState(6);
	const { helpArticles } = useBlumeHelpCenter();

	useImperativeHandle(ref, () => ({
		showAllResults: () => {
			if (isPip) {
				setLimit(searchResults.length);
			} else {
				navigate(`/help/category?search=${encodeURI(searchQuestion)}`);
				dismiss();
			}
		},
	}));

	const searchResults = useMemo(() => {
		const results = [];
		const articles = helpArticles.map(entry => ({
			data: entry.children,
		}));

		// deep search
		articles.forEach(({ data }) => {
			data.forEach(entry => {
				searchQuestion
					?.toLowerCase()
					.split(' ')
					.forEach(text => {
						if (entry.textContent.join(' ').toLowerCase().includes(text)) {
							results.push(entry);
						}
					});
			});
		});
		return results;
	}, [searchQuestion]);

	const openArticle = article => {
		if (onSelect) {
			onSelect(article);
		} else {
			navigate(`/help/category/${article.path}`);
		}
		dismiss();
	};

	return (
		<RecentSection>
			{!isPip && (
				<SearchInfoPeek>
					<SearchOutlinedIcon />
					<strong>{searchQuestion}</strong>
					<span>Press enter to view all results</span>
				</SearchInfoPeek>
			)}

			<ul className="list-group">
				{_.map(searchResults.slice(0, limit), function (entry, i) {
					return (
						<li
							key={i}
							onClick={() => openArticle(entry)}
							className={`list-group-item ${isPip ? 'pip-item' : ''}`}
							data-testid={'PatientHelpSearchResults'}
						>
							<StickyNote2OutlinedIcon />
							<span>{entry.textContent[0]}</span>
							<ChevronRightOutlinedIcon />
						</li>
					);
				})}
			</ul>
		</RecentSection>
	);
});
export { SearchResults };

const RecentResults = () => {
	const { t } = useTranslation('helpCenter');
	const recentlyViewed = useMemo(() => {
		return [
			{ title: 'Using the global search feature' },
			{ title: 'Using the global search feature' },
			{ title: 'Using the global search feature' },
			{ title: 'Using the global search feature' },
		];
	}, []);

	const recentlySearched = useMemo(() => {
		return [{ title: 'how to search' }, { title: 'how to search' }, { title: 'how to search' }];
	}, []);

	return (
		<>
			{!_.isEmpty(recentlyViewed) && (
				<RecentSection>
					<h4>{t('RECENTLY VIEWED')}</h4>
					<ul className="list-group">
						{_.map(recentlyViewed, function (entry, i) {
							return (
								<li key={i} className="list-group-item">
									<StickyNote2OutlinedIcon />
									<span>{entry.title}</span>
									<ChevronRightOutlinedIcon />
								</li>
							);
						})}
					</ul>
				</RecentSection>
			)}

			{!_.isEmpty(recentlySearched) && (
				<RecentSection>
					<h4>{t('RECENTLY SEARCHED')}</h4>
					<ul className="list-group">
						{_.map(recentlySearched, function (entry, i) {
							return (
								<li key={i} className="list-group-item">
									<SearchOutlinedIcon />
									<span>{entry.title}</span>
								</li>
							);
						})}
					</ul>
				</RecentSection>
			)}
		</>
	);
};
