import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import moment from 'moment';

import { useScheduleStore } from '../../../../stores/schedule';
import Tag from './Tag';

const Details = ({ orderset, ordersetId, location, address, startTime, endTime, disableNavigation, sx, light }) => {
	const jumpToStep = useScheduleStore(state => state.jumpToStep);

	const { t } = useTranslation('scheduling');

	const apptStartTime = moment(startTime);
	const apptEndTime = moment(endTime);

	return (
		<Box
			display="grid"
			gap={{ xs: '32px' }}
			justifyContent="space-between"
			sx={{
				padding: { xs: '20px', md: '24px' },
				borderRadius: '8px',
				background: light ? '#ffffff' : '#F9FAFB',
				gridTemplateColumns: { xs: '1fr', md: '1fr' },
				width: '90%',
				...sx,
			}}
		>
			<Tag
				heading={t('Selected Imaging Procedure and imaging center')}
				icon={
					<Box
						component="svg"
						fill="none"
						height={{ xs: '18px', md: '24px' }}
						marginTop={{ xs: '4px', md: 0 }}
						viewBox="0 0 24 24"
						width={{ xs: '18px', md: '24px' }}
					>
						<path
							d="M10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11Z"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							d="M10.3 15H7C5.93913 15 4.92172 15.4214 4.17157 16.1716C3.42143 16.9217 3 17.9391 3 19V21"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							d="M17 20C18.6569 20 20 18.6569 20 17C20 15.3431 18.6569 14 17 14C15.3431 14 14 15.3431 14 17C14 18.6569 15.3431 20 17 20Z"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							d="M21 21.0001L19.1 19.1001"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
					</Box>
				}
				text={
					<>
						<Box
							color="#101828"
							component="p"
							fontSize={{ xs: '14px', md: '20px' }}
							fontWeight={500}
							lineHeight={{ xs: '20px', md: '24px' }}
							sx={{ margin: '10px 0 6px' }}
						>
							{ordersetId} - {orderset} {t('at')}{' '}
							<ButtonBase
								component="a"
								data-testid="details-jump-to-location-btn"
								fontSize="20px"
								fontWeight={500}
								sx={{ color: '#42A5F5' }}
								onClick={disableNavigation ? null : () => jumpToStep(1)}
							>
								{location}
							</ButtonBase>
						</Box>
						<Box
							color="#101828"
							component="p"
							fontSize={{ xs: '12px', md: '16px' }}
							lineHeight="18px"
							sx={{ margin: 0 }}
						>
							{address}
						</Box>
					</>
				}
			/>
			<Tag
				heading={t('Date and time')}
				icon={
					<Box
						component="svg"
						fill="none"
						height={{ xs: '18px', md: '24px' }}
						viewBox="0 0 24 24"
						width={{ xs: '18px', md: '24px' }}
					>
						<path
							d="M21 7.5V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H8.5"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							d="M16 2V6"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							d="M8 2V6"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							d="M3 10H8"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							d="M17.5 17.5L16 16.25V14"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							d="M22 16C22 17.5913 21.3679 19.1174 20.2426 20.2426C19.1174 21.3679 17.5913 22 16 22C14.4087 22 12.8826 21.3679 11.7574 20.2426C10.6321 19.1174 10 17.5913 10 16C10 14.4087 10.6321 12.8826 11.7574 11.7574C12.8826 10.6321 14.4087 10 16 10C17.5913 10 19.1174 10.6321 20.2426 11.7574C21.3679 12.8826 22 14.4087 22 16Z"
							stroke="#344054"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
					</Box>
				}
				text={
					<Typography
						noWrap
						color="#101828"
						fontSize={{ xs: '14px', md: '18px' }}
						fontWeight={500}
						lineHeight={{ xs: '20px', md: '21px' }}
						mt={1}
					>
						{apptStartTime.isValid() && apptEndTime.isValid()
							? `${apptStartTime.format('DD')} ${t(apptStartTime.format('MMM'))}, ${apptStartTime.format(
									'YYYY (h:mm'
							  )} ${t(apptStartTime.format('a'))} - ${apptEndTime.format('h:mm')} ${t(
									apptEndTime.format('a')
							  )})`
							: null}
					</Typography>
				}
			/>
		</Box>
	);
};

export default Details;
